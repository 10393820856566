<template>
  <router-link
    :to="`/class-management/universities/${university.abbrev}/classes`"
    @click="loadUniversity()"
  >
    <div
      class="w-44 h-44 bg-orange-tint rounded-3xl rounded-tr-md relative text-center mr-8 transition duration-300 ease-in-out hover:shadow-lg hover:ring-orange cursor-pointer"
    >
      <p class="text-orange font-bold pt-10 pb-2 uppercase text-xl">
        {{ university.abbrev }}
      </p>
      <p class="px-2">
        {{ university.classes.length }} classes offered
      </p>
      <div
        class="bg-orange w-full absolute bottom-0 left-0 right-0 h-12 rounded-br-3xl rounded-bl-3xl flex items-center justify-center"
      >
        <p class="text-white font-bold">
          {{ university.students.length }} students
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  props: {
    university: Object,
  },
  methods: {
    ...mapMutations(["setPreviewedUniversity"]),
    ...mapActions(["get_university_classes"]),
    async loadUniversity() {
      this.setPreviewedUniversity(this.university),
        await this.get_university_classes();
    },
  },
};
</script>
