<template>
  <page
    kebab="tutors"
    title="Tutor Application"
    :breadcrumb="`/universities/${previewed_university.abbrev}/tutors/applications`"
    :back_to="`/tutors/universities/${previewed_university.abbrev}`"
  >
    <application />
  </page>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Page from "../../components/base/page.vue";
import application from "../../components/tutorCards/application.vue";
export default {
  components: {
    application,
    Page,
  },
  created() {
    this.get_university_tutors();
    this.get_university_tutor_applications();
  },
  computed: {
    ...mapState(["previewed_university"]),
  },
  methods: {
    ...mapActions([
      "get_university_tutors",
      "get_university_tutor_applications",
    ]),
  },
};
</script>
