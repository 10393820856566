<template>
  <page kebab="class-management">
    <universities />
  </page>
</template>

<script>
import { mapActions } from "vuex";
import page from "../components/base/page.vue";
import Universities from "../components/classCards/universities.vue";
export default {
  created() {
    this.loadUniversities();
  },
  methods: {
    ...mapActions(["get_universities"]),
    async loadUniversities() {
      await this.get_universities();
    },
  },
  components: { page, Universities },
};
</script>
