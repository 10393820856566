<template>
  <page kebab="tutors">
    <universities />
  </page>
</template>

<script>
import page from "../components/base/page.vue";
import Universities from "../components/tutorCards/universities.vue";
export default {
  components: { page, Universities },
};
</script>
