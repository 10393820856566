<template>
  <div
    v-if="visible"
    class="fixed flex items-center justify-center top-0 left-0 right-0 bottom-0 bg-shark bg-opacity-50 z-50"
  >
    <div
      class="w-96 py-8 px-12 bg-white rounded-3xl rounded-tr-md text-center relative"
    >
      <div
        class="absolute -right-4 -top-4 w-12 h-12 bg-white hover:text-orange shadow flex items-center justify-center rounded-full cursor-pointer"
        @click="visible = false"
      >
        <p class="text-2xl transition duration-300 ease font-bold">
          x
        </p>
      </div>
      <p class="mx-auto text-xl text-orange font-bold pb-4">
        {{ title }}
      </p>
      <slot> nothing to show </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    active: Boolean,
  },
  data() {
    return {
      visible: this.active,
    };
  },
};
</script>
