<template>
  <div class="w-full p-2 py-6 flex items-center justify-between">
    <div class="w-64 flex items-center">
      <img
        :src="user.img_url"
        alt=""
        class="bg-beige w-12 h-12 mr-2 rounded-full"
      >
      <div class="w-48">
        <p class="font-bold -mb-1 truncate">
          {{ user.name }}
        </p>
        <p class="font-extralight text-sm truncate">
          {{ user.username }}
        </p>
      </div>
    </div>
    <div class="w-48">
      <p class="text-orange truncate">
        {{ user.email }}
      </p>
    </div>
    <div class="w-36">
      <p>
        <span class="text-orange">{{ user.referrals.length }}</span> invitations
        sent
      </p>
    </div>
    <Button
      text="view"
      type="ghost"
      @click="
        router.replace(
          `/tutors/universities/${previewed_university.abbrev}/applicants/${user.tutor_application}`
        )
      "
    />
  </div>
  <div class="w-full h-2 rounded-3xl bg-beige" />
</template>

<script>
import Button from "../base/button.vue";
import { useRouter } from "vue-router";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["previewed_university"]),
  },
  components: { Button },
  props: {
    user: Object,
  },
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>
