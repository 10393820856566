<template>
  <router-link
    :to="`/tutors/universities/${university.abbrev}/`"
    @click="setPreviewedUniversity(university)"
  >
    <div
      class="w-44 h-44 bg-orange-tint rounded-3xl rounded-tr-md relative text-center mr-8 transition duration-300 ease-in-out hover:shadow-lg hover:ring-orange cursor-pointer"
    >
      <p class="text-orange font-bold pt-10 pb-2 uppercase text-xl">
        {{ university.abbrev }}
      </p>
      <!-- <p class="px-2">{{ university.tutors.length }} tutors</p>
      <div
        class="bg-orange w-full absolute bottom-0 left-0 right-0 h-12 rounded-br-3xl rounded-bl-3xl flex items-center justify-center"
      >
        <p class="text-white font-bold">
          {{ university.applications.length }} applicants
        </p>
      </div> -->
    </div>
  </router-link>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    university: Object,
  },
  methods: {
    ...mapMutations(["setPreviewedUniversity"]),
  },
};
</script>
