<template>
  <div class="w-full inline-flex relative">
    <Nav :active-link="kebab" />
    <div class="absolute left-96">
      <heading
        :title="title"
        :breadcrumb="breadcrumb"
        :back="back_to"
      />
      <slot>
        <div class="w-full mx-auto text-center">
          <p class="mx-auto">
            no content
          </p>
          <p>
            <router-link
              to="/"
              class="text-orange"
            >
              go back to home
            </router-link>
          </p>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import Nav from "../nav/nav.vue";
import Heading from "./heading.vue";
export default {
  components: { Nav, Heading },
  props: {
    kebab: String,
    title: String,
    breadcrumb: {
      type: String,
      default: "",
    },
    back_to: {
      type: String,
      default: null,
    },
  },
};
</script>
