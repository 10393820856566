<template>
  <div class="w-96 p-4 bg-beige fixed left-0 top-0 bottom-0">
    <p class="text-5xl text-orange font-bold mx-4 mt-4 mb-20">
      team
    </p>
    <router-link
      to="/dashboard"
    >
      <navlink
        :is-active="activeLink == 'dashboard'"
        :notifs="0"
        title="dashboard"
      />
    </router-link>
    <router-link
      to="/class-management"
    >
      <navlink
        :is-active="activeLink == 'class-management'"
        :notifs="1"
        title="class admin"
      />
    </router-link>
    <router-link
      to="/tutors"
    >
      <navlink
        :is-active="activeLink == 'tutors'"
        :notifs="3"
        title="tutors"
      />
    </router-link>
    <member
      :name="user.name"
      :email="user.email"
      position="Family Member"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Member from "./member.vue";
import navlink from "./navlink.vue";
export default {
  computed: {
    ...mapState(["user"]),
  },
  components: { navlink, Member },
  props: ["activeLink"],
};
</script>
