<template>
  <label>
    <p class="text-orange text-lg my-2 text-left">{{ label }}</p>
    <input
      type="text"
      :name="name"
      :value="value"
      class="appearance-none border-2 border-shark focus:border-orange focus:text-orange w-full rounded-lg p-2 mb-4 truncate"
      :class="{ 'w-36': short }"
      @input="$emit('update:value', $event.target.value)"
    >
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    short: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:value"],
  /* Can add validation here
  watch: {
    value: {
      handler(newValue, oldValue) {

      },
    },
  }, */
  computed: {
    name() {
      return this.label.toLowerCase();
    },
  },
  methods: {
    onInput(event) {
      // Can add validation here
      this.$emit("input", event.target.value);
    },
    // onChange(event) { // Supports .lazy
    //   // Can add validation here
    //   this.$emit('change', event.target.value);
    // },
  },
};
</script>
