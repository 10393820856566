import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/index.css";
import { initializeApp } from "firebase/app"; // Import the functions you need from the SDKs you need


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAELZtoZKNEfiZPjpZ_LsYUf3CzeHvKIec",
  authDomain: "team-tutoruu.firebaseapp.com",
  projectId: "team-tutoruu",
  storageBucket: "team-tutoruu.appspot.com",
  messagingSenderId: "391902498552",
  appId: "1:391902498552:web:e724531ce468759dad350d",
};

// Initialize Firebase
initializeApp(firebaseConfig);

createApp(App).use(store).use(router).mount("#app");
