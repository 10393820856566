<template>
  <div
    v-if="error"
    class="bg-red-400 w-80 h-20 mx-auto text-white font-bold flex items-center justify-center"
  >
    {{ error }}
  </div>
  <modal
    title="login to get shit done"
    active
  >
    <forminput
      v-model:value="email"
      label="work email"
    />
    <forminput
      v-model:value="password"
      label="password"
    />
    <div class="h-16 mt-4">
      <Button
        v-if="email != '' && password != ''"
        type="primary"
        class="float-right"
        text="sign in"
        @click="signIn()"
      />
      <Button
        v-else
        class="float-right"
        text="sign in"
        type="disabled"
      />
    </div>
  </modal>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { mapMutations } from "vuex";
import modal from "../components/base/modal.vue";
import Forminput from "../components/base/forminput.vue";
import Button from "../components/base/button.vue";
export default {
  components: { modal, Forminput, Button },
  data() {
    return {
      loading: false,
      error: null,
      email: "",
      password: "",
    };
  },
  methods: {
    ...mapMutations(["setUser"]),
    async signIn() {
      const auth = getAuth();
      this.loading = true;
      this.error = null;
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          this.setUser(user);
          this.$router.push("../");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          this.error = errorMessage + errorCode;
        });
      this.loading = false;
    },
  },
};
</script>
