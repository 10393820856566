<template>
  <page
    v-if="previewed_university"
    kebab="class-management"
    title="Classes"
    :breadcrumb="`/universities/${previewed_university.abbrev}`"
  >
    <div :key="updateClasses">
      <div
        v-for="(Class, index) in previewed_university.classes"
        :key="index"
        class="inline-flex flex-wrap justify-center relative"
      >
        <Class :Class="Class" />
      </div>
    </div>
    <Button
      class="fixed right-5 bottom-10"
      text="add class"
      type="primary"
      icon="https://img.icons8.com/fluency-systems-filled/50/000000/upload.png"
      @click="
        fireModal = true;
        modalKey++;
      "
    />
    <modal :key="modalKey" title="add class" :active="fireModal">
      <!-- eslint-disable-next-line vue/no-v-model-argument -->
      <form-input v-model:value="name" label="class name" />
      <!-- eslint-disable-next-line vue/no-v-model-argument -->
      <form-input v-model:value="desc" label="class abbreviation" short />
      <div class="h-16 mt-4">
        <div v-if="loading" class="float-right animate-pulse">loading...</div>
        <Button
          v-else-if="name != '' && desc != ''"
          class="float-right"
          text="add class"
          :type="name != '' && desc != '' ? `primary` : `disabled`"
          @click="addClass()"
        />
        <Button v-else class="float-right" text="add class" type="disabled" />
      </div>
    </modal>
  </page>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Class from "../../components/classCards/class.vue";
import page from "../../components/base/page.vue";
import Button from "../../components/base/button.vue";
import Modal from "../../components/base/modal.vue";
import FormInput from "../../components/base/forminput.vue";
export default {
  components: { page, Class, Button, Modal, FormInput },
  data() {
    return {
      fireModal: false,
      modalKey: 0,
      loading: false,
      name: "",
      desc: "",
      updateClasses: 0,
    };
  },
  computed: {
    ...mapState(["previewed_university"]),
  },
  mounted() {
    if (!this.previewed_university) this.$router.replace("../../");
  },
  watch: {
    previewed_university: {
      handler: function () {
       this.updateClasses++
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["create_class"]),
    async addClass() {
      this.loading = true;
      await this.create_class({ name: this.name, desc: this.desc });
      this.updateClasses++
      this.loading = false;
      this.name = "";
      this.desc = "";
      this.fireModal = false;
      this.modalKey++;
    },
  },
};
</script>
