<template>
  <page
    kebab="tutors"
    title="Tutors"
    :breadcrumb="`/universities/${previewed_university.abbrev}/tutors`"
  >
    <div class="absolute right-6 top-6">
      <Button
        text="view all tutors"
        @click="() => { $router.push(`/tutors/universities/${previewed_university._id}/search`) }"
      />
    </div>
    <applications />
  </page>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Page from "../../components/base/page.vue";
import Button from "../../components/base/button.vue";
import applications from "../../components/tutorCards/applications.vue";
export default {
  components: {
    applications,
    Page,
    Button,
  },
  created() {
    this.get_university_tutors();
    this.get_university_tutor_applications();
  },
  computed: {
    ...mapState(["previewed_university"]),
  },
  methods: {
    ...mapActions([
      "get_university_tutors",
      "get_university_tutor_applications",
    ]),
  },
};
</script>
