import { createRouter, createWebHistory } from "vue-router";
import { getAuth } from "firebase/auth";
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import ClassManagement from '../views/ClassManagement.vue'
import TutorManagement from '../views/Tutors.vue'
import Classes from '../views/university/Classes.vue' 
import Tutors from '../views/university/Tutors.vue'
import TutorsSearch from '../views/university/TutorsSearch.vue'
import TutorApplication from '../views/university/TutorApplication.vue'

const getCurrentUser = () => {
  const auth = getAuth();
  return auth.currentUser;
};

const routes = [
  {
    path: "/",
    redirect: "/class-management",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/class-management",
    name: "Class Management",
    component: ClassManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/class-management/universities/:university/classes",
    name: "Classes",
    component: Classes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tutors",
    name: "Tutors",
    component: TutorManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tutors/universities/:university",
    name: "University Tutors",
    component: Tutors,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tutors/universities/:university/search",
    name: "Search University Tutors",
    component: TutorsSearch,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tutors/universities/:university/applicants/:username",
    name: "Tutor Application",
    component: TutorApplication,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !getCurrentUser()) {
    next({ name: "Login" });
  } else if (to.name === "Login" && getCurrentUser()) {
    next("/");
  } else {
    next();
  }
});

export default router;
