<template>
  <div class="mr-4 my-4 group">
    <div
      class="bg-orange-tint w-64 h-24 absolute p-4 rounded-3xl rounded-tr-md"
    >
      <div class="float-right flex items-center">
        <div
          v-for="(action, index) in actions"
          :key="index"
          class="w-6 h-6 flex justify-center items-center ml-1 ring-1 rounded-full cursor-pointer transition duration-200 ease-in-out group"
          :class="{
            'ring-orange hover:bg-orange hover:text-white text-orange':
              action.primary,
            'ring-blue-700 hover:bg-blue-700 hover:text-white text-blue-700':
              action.secondary,
            'ring-red-700 hover:bg-red-700 hover:text-white text-red-700':
              action.danger,
            'ring-green-700 hover:bg-green-700 hover:text-white text-green-700':
              action.success,
            'ring-gray-600 text-gray-600 cursor-not-allowed': action.disabled,
          }"
          @click="$emit('action', {value: action.value, Class: Class, grade: grade, professor: professor, verified: verified})"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 30 30"
            class="fill-current w-4 h-4"
          >
            <path :d="action.svgPath" />
          </svg>
        </div>
      </div>
      <p class="font-bold text-orange uppercase -mb-2">
        {{ Class.desc }}
      </p>
      <p class="text-lg font-bold lowercase truncate">
        {{ Class.name }}
      </p>
      <div class="flex items-center justify-start">
        <div
          v-if="verified"
          class="
           absolute right-4 bottom-4
            w-4
            h-4
            bg-orange
            rounded-full
            ring-2 ring-orange ring-offset-1
            flex
            items-center
            justify-center
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            class="w-3 h-3"
            viewBox="0 0 172 172"
            style="fill: #000000"
          >
            <g
              fill="none"
              fill-rule="nonzero"
              stroke="none"
              stroke-width="1"
              stroke-linecap="butt"
              stroke-linejoin="miter"
              stroke-miterlimit="10"
              stroke-dasharray=""
              stroke-dashoffset="0"
              font-family="none"
              font-weight="none"
              font-size="none"
              text-anchor="none"
              style="mix-blend-mode: normal"
            >
              <path
                d="M0,172v-172h172v172z"
                fill="none"
              />
              <g fill="#ffffff">
                <path
                  d="M69.56683,137.03383c-1.34017,1.34733 -3.16767,2.09983 -5.06683,2.09983c-1.89917,0 -3.72667,-0.7525 -5.06683,-2.09983l-40.463,-40.47017c-4.19967,-4.19967 -4.19967,-11.008 0,-15.2005l5.06683,-5.06683c4.19967,-4.19967 11.00083,-4.19967 15.2005,0l25.2625,25.2625l68.2625,-68.2625c4.19967,-4.19967 11.008,-4.19967 15.2005,0l5.06683,5.06683c4.19967,4.19967 4.19967,11.008 0,15.2005z"
                />
              </g>
            </g>
          </svg>
        </div>

        <p class="w-1/2">
          Grade:
          <span class="font-bold text-orange uppercase">{{ grade }}</span>
        </p>
        <p class="w-1/2">
          <span class="font-bold text-orange">{{ professor }}</span>
        </p>
      </div>
    </div>
    <!-- next sprint - preview classes with info -->
    <!-- <router-link :to="`/class-management/universities/${previewed_university.abbrev}/classes/${Class.desc}`">
            <div class="w-64 h-24 absolute bg-shark bg-opacity-0 text-opacity-0 rounded-3xl rounded-tr-md flex transition duration-300 ease-in items-center justify-center text-orange font-bold text-xl group-hover:bg-opacity-50 group-hover:text-opacity-100 cursor-pointer">
                view
            </div> 
        </router-link> -->
    <div
      class="w-64 h-24 bg-orange-tint rounded-3xl rounded-tr-md shadow-lg p-4"
    />
  </div>
</template>

<script>
export default {
  props: {
    Class: Object,
    grade: String,
    professor: String,
    verified: Boolean,
    actions: Array,
  },
  emits: ["action"],
};
</script>
