<template>
  <div class="bg-orange-tint w-full mx-4 rounded-3xl rounded-tr-md p-12">
    <p class="text-2xl font-bold mb-8 text-orange">
      Completed Applications
    </p>
    <applicant-row
      v-for="(application, index) in previewed_university.applications"
      :key="index"
      :user="application.uid"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import applicantRow from "./applicantRow.vue";
export default {
  components: { applicantRow },
  computed: {
    ...mapState(["previewed_university"]),
  },
};
</script>
