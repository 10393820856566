<template>
  <div
    class="w-80 relative py-4 px-4 m-2 flex items-center rounded-3xl rounded-tr-md"
    :class="{ 'bg-orange-tint': isActive }"
  >
    <div class="w-10 h-10 mx-2 rounded-full bg-gray-300" />
    <p
      class="text-xl font-bold"
      :class="{ 'text-orange': isActive }"
    >
      {{ title }}
    </p>
    <div
      v-if="notifs > 0"
      class="absolute right-4 w-8 h-8 rounded-full bg-orange text-white font-bold flex items-center justify-center"
    >
      {{ notifs }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    isActive: {
      type: Boolean,
      default: false,
    },
    notifs: Number,
  },
};
</script>
