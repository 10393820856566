<template>
  <div class="bg-beige w-full mx-4 rounded-3xl rounded-tr-md p-12">
    <p class="text-2xl font-bold mb-8">
      universities
    </p>
    <div class="flex justify-start items-center">
      <university
        v-for="(university, index) in universities"
        :key="index"
        :university="university"
      />
      <button
        class="h-10 w-10 flex items-center mx-auto rounded-full bg-orange-tint shadow transition duration-200 ease-in hover:shadow-lg cursor-pointer"
        @click="
          fireModal = true;
          modalKey++;
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          class="mx-auto"
          width="24"
          height="24"
          viewBox="0 0 172 172"
          style="fill: #000000"
        >
          <g
            fill="none"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
          >
            <path
              d="M0,172v-172h172v172z"
              fill="none"
            />
            <g fill="#ff8303">
              <path
                d="M143.33333,78.83333h-50.16667v-50.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667c-3.956,0 -7.16667,3.21067 -7.16667,7.16667v50.16667h-50.16667c-3.956,0 -7.16667,3.21067 -7.16667,7.16667c0,3.956 3.21067,7.16667 7.16667,7.16667h50.16667v50.16667c0,3.956 3.21067,7.16667 7.16667,7.16667c3.956,0 7.16667,-3.21067 7.16667,-7.16667v-50.16667h50.16667c3.956,0 7.16667,-3.21067 7.16667,-7.16667c0,-3.956 -3.21067,-7.16667 -7.16667,-7.16667z"
              />
            </g>
          </g>
        </svg>
      </button>
      <modal
        :key="modalKey"
        title="add university"
        :active="fireModal"
      >
        <form-input
          v-model:value="name"
          label="university name"
        />
        <form-input
          v-model:value="abbrev"
          label="university abbreviation"
          short
        />
        <form-input
          v-model:value="country"
          label="country"
          short
        />
        <div class="h-16 mt-4">
          <div
            v-if="loading"
            class="float-right animate-pulse"
          >
            loading...
          </div>
          <Button
            v-else-if="name != '' && abbrev != ''"
            class="float-right"
            text="add university"
            :type="name != '' && abbrev != '' ? `primary` : `disabled`"
            @click="
              create_university({
                name: name,
                abbrev: abbrev,
                country: country,
              });
              fireModal = false;
              modalKey++;
            "
          />
          <Button
            v-else
            class="float-right"
            text="add university"
            type="disabled"
          />
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import University from "./university.vue";
import Button from "../../components/base/button.vue";
import Modal from "../../components/base/modal.vue";
import FormInput from "../../components/base/forminput.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: { University, Button, Modal, FormInput },
  computed: {
    ...mapState(["universities"]),
  },
  data() {
    return {
      fireModal: false,
      modalKey: 0,
      name: "",
      abbrev: "",
      country: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(["create_university"]),
  },
};
</script>
