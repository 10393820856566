<template>
  <div class="mx-4 my-4 group" v-if="!!Class">
    <div
      class="bg-orange-tint w-64 h-24 absolute p-4 rounded-3xl rounded-tr-md"
    >
      <p class="font-bold text-orange uppercase -mb-2">
        {{ Class.desc }}
      </p>
      <p class="text-lg font-bold lowercase truncate">
        {{ Class.name }}
      </p>
      <div class="flex items-center justify-start">
        <p class="w-1/2">
          <span
            class="text-orange font-bold"
          >{{ Class.students.length }}
          </span>
          students
        </p>
        <p class="w-1/2">
          <span class="text-orange font-bold">{{ Class.courses.length }} </span>
          tutors
        </p>
      </div>
    </div>
    <!-- next sprint - preview classes with info -->
    <!-- <router-link :to="`/class-management/universities/${previewed_university.abbrev}/classes/${Class.desc}`">
            <div class="w-64 h-24 absolute bg-shark bg-opacity-0 text-opacity-0 rounded-3xl rounded-tr-md flex transition duration-300 ease-in items-center justify-center text-orange font-bold text-xl group-hover:bg-opacity-50 group-hover:text-opacity-100 cursor-pointer">
                view
            </div> 
        </router-link> -->
    <div
      class="w-64 h-24 bg-orange-tint rounded-3xl rounded-tr-md shadow-lg p-4"
    />
  </div>
</template>

<script>
export default {
  props: {
    Class: Object,
  },
};
</script>
