<template>
  <div
    v-if="user && user.tutor"
    class="p-4 my-2 pb-10 w-full mx-auto shadow-md rounded-xl hover:shadow-2xl"
  >
    <div class="-grid grid-cols-3 gap-2">
      <div class="flex items-center justify-between z-10">
        <div class="z-20">
          <h2 class="font-bold text-xl truncate w-32 capitalize">
            {{ user.name }}
          </h2>
        </div>
        <div
          class="ring-green-600 bg-green-100 flex justify-center items-center rounded-full"
          style="margin-top: -2px;"
        >
          <p class="font-bold text-green-600 text-xs text-center whitespace-nowrap p-1">
            {{ user.tutor.rate }} EGP
          </p>
        </div>
      </div>

      <div class="py-2">
        <div class="w-48 pb-2">
          <h4 class="font-bold pb-2 text-sm lg:text-md pr-4">
            Bio
          </h4>
          <p class="font-extralight line-clamp-3 pr-4">
            {{ user.bio }}
          </p>
        </div>
        <div class="w-48">
          <h4 class="font-bold pb-2 text-sm lg:text-md">
            teaching <span class="font-bold text-orange">{{ user.tutor.courses.length }}</span> classes
          </h4>
        </div>
      </div>
    </div>
    <!-- <div class="absolute right-5 bottom-5">
      <Button type="primary" text="check me out" @click="() => { this.$emit('clickthrough'); router.push(`/tutors/${username}`); }" />
    </div> -->
  </div>
</template>

<script>
import { useRouter } from "vue-router";
// import Button from './button.vue';


export default {
  components: {
    // Button,
  },
  props: {
    user: Object,
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
    //   tagLimit: 1,
    }
  },
};
</script>