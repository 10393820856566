<template>
  <div class="flex items-center mx-2 absolute bottom-10">
    <img
      v-if="img_url"
      :src="img_url"
      class="w-20 h-20 rounded-full"
    >
    <div
      v-else
      class="w-20 h-20 bg-orange rounded-full"
    />
    <div class="mx-3">
      <p class="font-extralight">
        {{ email }}
      </p>
      <p class="text-xl font-bold -mb-2">
        {{ name }}
      </p>
      <p class="text-xl font-bold text-orange">
        {{ position }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: String,
    name: String,
    position: String,
    img_url: String,
  },
};
</script>
