<template>
  <div class="flex items-center">
    <div
      v-if="back"
      class="flex justify-end ml-8 -mr-12 my-12"
    >
      <router-link
        :to="back"
        class="w-12 h-auto"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 172 172"
          style="fill: #000000"
        >
          <g
            fill="none"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
          >
            <path
              d="M0,172v-172h172v172z"
              fill="none"
            />
            <g fill="#ff8303">
              <path
                d="M120.26563,10.25281c-1.78719,0.05375 -3.48031,0.80625 -4.73,2.08281l-68.8,68.8c-2.6875,2.6875 -2.6875,7.04125 0,9.72875l68.8,68.8c1.72,1.80062 4.28656,2.52625 6.70531,1.89469c2.40531,-0.63156 4.28656,-2.51281 4.91813,-4.91813c0.63156,-2.41875 -0.09406,-4.98531 -1.89469,-6.70531l-63.93563,-63.93563l63.93563,-63.93562c2.02906,-1.97531 2.64719,-4.99875 1.54531,-7.61906c-1.11531,-2.60688 -3.70875,-4.27313 -6.54406,-4.1925z"
              />
            </g>
          </g>
        </svg>
      </router-link>
    </div>
    <div class="mx-20 my-12 block">
      <p class="text-xl text-orange -mb-2">
        {{ breadcrumb }}
      </p>
      <p class="text-3xl font-bold">
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    breadcrumb: String,
    back: {
      type: String,
      default: null,
    },
  },
};
</script>
