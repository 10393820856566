<template>
  <page kebab="dashboard" />
</template>

<script>
import page from "../components/base/page.vue";
export default {
  components: { page },
};
</script>
