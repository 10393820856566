<template>
  <div
    class="py-4 px-8 text-xl font-bold rounded-full rounded-tr-md flex items-center justify-center cursor-pointer"
    :class="{
      'px-4': icon,
      'bg-orange': type == 'primary',
      'bg-gray-300': type == 'disabled',
    }"
  >
    <img
      v-if="icon"
      :src="icon"
      class="w-10 h-10 mr-3"
    >
    <p
      class="text-xl font-bold"
      :class="{
        'text-white': type == 'primary' || type == 'disabled',
        'text-orange': type == 'ghost',
      }"
    >
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    type: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
