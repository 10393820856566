<template>
  <page kebab="search">
    <input
      v-model="inputValue"
      name="tutor-search"
      type="text"
      class="
        w-full
        peer
        max-w-3xl
        mx-auto
        appearance-none
        outline-none
        bg-white
        rounded-xl
        placeholder-gray-500
        ring-orange focus:ring-offset-4
        py-2
        px-4
        focus:placeholder-white
        ring-4 ring-opacity-50 
        focus:ring-opacity-100
        transition
        duration-200
        ease-out
        text-shark
        focus:text-orange
        shadow-inner
        focus:shadow-none
      "
      placeholder="search by name or username"
      @keyup="getTutors()"
    >
    <div
      :key="update"
      class="pt-20 pl-4 grid grid-cols-2 gap-6"
    >
      <tutor-card
        v-for="tutor, index in tutors"
        :key="index"
        :user="tutor"
      />
    </div>
  </page>
</template>

<script>
import TutorCard from "../../components/tutorCards/tutor.vue";
import { mapState } from 'vuex';
import axios from 'axios';
import Page from "../../components/base/page.vue";

export default {
  components: { TutorCard, Page },
  data() {
      return {
          tutors: [],
          update: 0,
          inputValue: '',
      }
  },
  computed: {
      ...mapState(['previewed_university'])
  },
  methods: {
    async getTutors() {
      if (this.inputValue.length > 0)
        await axios
          .get(`https://tutoruu0.herokuapp.com/api/tutor/search/${this.inputValue}`)
          .then((data) => {
            this.tutors = data.data;
            this.update++
          })
          .catch((error) => {
            console.error(error.response);
          });
    },
  },
};
</script>
