<template>
  <div class="w-full flex items-start justify-start">
    <img
      :src="application.uid.img_url"
      alt=""
      class="mx-4 w-28 h-28 rounded-full bg-orange-tint"
    >
    <div class="w-full">
      <div class="w-full flex flex-wrap my-8 items-center justify-between">
        <div class="w-80">
          <p class="font-bold text-3xl -mb-1">
            {{ application.uid.name }}
          </p>
          <p class="font-extralight text-lg">
            @{{ application.uid.username }}
          </p>
        </div>
        <div class="flex absolute right-0">
          <div
            class="py-2 text-xl px-11 mx-2 rounded-xl ring-green-400 ring-4 text-green-400 font-bold text-center hover:bg-green-400 hover:text-white transition cursor-pointer"
            @click="acceptTutor()"
          >
            accept
          </div>
          <a
            :href="`mailto:${application.uid.email}`"
            class="py-2 text-xl px-11 mx-2 rounded-xl ring-orange ring-4 text-orange font-bold text-center hover:bg-orange hover:text-white transition cursor-pointer"
          >
            email
          </a>
        </div>
      </div>
      <div class="w-full flex my-6">
        <div class="w-40">
          <p class="font-bold">
            Phone
          </p>
          <p class="font-extralight">
            {{ application.uid.phone ? application.uid.phone : "-" }}
          </p>
        </div>
        <div class="w-40">
          <p class="font-bold">
            Class of
          </p>
          <p class="font-extralight">
            {{ application.uid.classOf ? "'" + application.uid.classOf : "-" }}
          </p>
        </div>
        <div class="w-40">
          <p class="font-bold">
            Email
          </p>
          <p class="font-extralight">
            {{ application.uid.email }}
          </p>
        </div>
      </div>
      <div class="w-full flex my-6">
        <div class="w-40">
          <p class="font-bold">
            Inperson
          </p>
          <p class="font-extralight">
            {{ application.inperson ? "Yes" : "No" }}
          </p>
        </div>
        <div class="w-40">
          <p class="font-bold">
            rate
          </p>
          <p class="font-extralight">
            {{ application.rate ? application.rate : "-" }}
          </p>
        </div>
        <div class="w-40">
          <p class="font-bold">
            Zoom Link
          </p>
          <p class="font-extralight">
            {{ application.zoom_link ? application.zoom_link : "-" }}
          </p>
        </div>
      </div>
      <div class="w-full my-8">
        <p class="font-bold">
          Classes
        </p>
        <p
          v-if="application.classes.length == 0"
          class="font-extralight"
        >
          No classes added yet
        </p>
        <div
          v-else
          class="flex flex-wrap"
        >
          <Class
            v-for="(Class, index) in application.classes"
            :key="index"
            :Class="Class.class_info"
            :grade="Class.grade"
            :professor="Class.professor"
            :verified="Class.verified"
            :actions="[
              {
                primary: !Class.verified,
                disabled: Class.verified,
                value: 'verify',
                svgPath:
                  'M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z',
              },
              {
                secondary: true,
                value: 'edit',
                svgPath:
                  'M 22.828125 3 C 22.316375 3 21.804562 3.1954375 21.414062 3.5859375 L 19 6 L 24 11 L 26.414062 8.5859375 C 27.195062 7.8049375 27.195062 6.5388125 26.414062 5.7578125 L 24.242188 3.5859375 C 23.851688 3.1954375 23.339875 3 22.828125 3 z M 17 8 L 5.2597656 19.740234 C 5.2597656 19.740234 6.1775313 19.658 6.5195312 20 C 6.8615312 20.342 6.58 22.58 7 23 C 7.42 23.42 9.6438906 23.124359 9.9628906 23.443359 C 10.281891 23.762359 10.259766 24.740234 10.259766 24.740234 L 22 13 L 17 8 z M 4 23 L 3.0566406 25.671875 A 1 1 0 0 0 3 26 A 1 1 0 0 0 4 27 A 1 1 0 0 0 4.328125 26.943359 A 1 1 0 0 0 4.3378906 26.939453 L 4.3632812 26.931641 A 1 1 0 0 0 4.3691406 26.927734 L 7 26 L 5.5 24.5 L 4 23 z',
              },
              {
                danger: true,
                value: 'delete',
                svgPath:
                  'M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z',
              },
            ]"
            class="mr-4"
            @action="handleClassActions($event)"
          />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-80 my-8">
          <p class="font-bold">
            Answer to question 1
          </p>
          <p class="font-extralight">
            {{
              application.answers[0]
                ? application.answers[0]
                : "Not answered yet"
            }}
          </p>
        </div>
        <div class="w-80 my-8">
          <p class="font-bold">
            Answer to question 2
          </p>
          <p class="font-extralight">
            {{
              application.answers[1]
                ? application.answers[1]
                : "Not answered yet"
            }}
          </p>
        </div>
        <div class="w-80 my-8">
          <p class="font-bold">
            Answer to question 3
          </p>
          <p class="font-extralight">
            {{
              application.answers[2]
                ? application.answers[2]
                : "Not answered yet"
            }}
          </p>
        </div>
        <div class="w-80 my-8">
          <p class="font-bold">
            Answer to question 4
          </p>
          <p class="font-extralight">
            {{
              application.answers[3]
                ? application.answers[3]
                : "Not answered yet"
            }}
          </p>
        </div>
        <div class="w-80 my-8">
          <p class="font-bold">
            Answer to question 5
          </p>
          <p class="font-extralight">
            {{
              application.answers[4]
                ? application.answers[4]
                : "Not answered yet"
            }}
          </p>
        </div>
      </div>
      <div class="flex">
        <div class="w-80 my-8">
          <p class="font-bold">
            transcript
          </p>
          <div
            v-if="application.transcript"
            class="flex justify-between w-full"
          >
            <p class="font-extralight">
              Uploaded
            </p>
            <a
              :href="application.transcript"
              target="_blank"
              class="w-32 text-center"
            >
              <p class="font-bold text-orange w-32 text-center cursor-pointer">
                view
              </p>
            </a>
          </div>
          <p
            v-else
            class="font-extralight"
          >
            Not submitted
          </p>
        </div>
        <div class="w-80 my-8">
          <p class="font-bold">
            Referrals
          </p>
          <p class="font-extralight">
            {{ application.uid.referrals.length }}
          </p>
        </div>
      </div>
    </div>
    <modal
      :key="modal.key"
      title="edit course"
      :active="modal.edit"
    >
      <p class="font-bold text-orange text-left">
        {{ modal.course.Class.desc }}
      </p>
      <p class="font-bold text-lg text-left mb-3">
        {{ modal.course.Class.name }}
      </p>
      <form-input
        v-model:value="modal.course.grade"
        label="course grade"
        short
      />
      <form-input
        v-model:value="modal.course.professor"
        label="course professor"
      />
      <div class="h-16 mt-4">
        <Button
          class="float-right"
          text="save"
          :type="modal.course.grade != '' && modal.course.professor != '' ? `primary` : `disabled`"
          @click="modal.course.grade != '' && modal.course.professor != ''? saveClass(): ''"
        />
      </div>
    </modal>
    <modal
      :key="modal.key"
      title="verify course"
      :active="modal.verify"
    >
      <p class="text-lg text-center mb-5">
        make sure the below information is accurate
      </p>
      <p class="text-left">
        grade: <span class="font-bold text-orange text-lg">{{ modal.course.grade }}</span>
      </p>
      <p class="text-left mb-3">
        professor: <span class="font-bold text-orange text-lg">{{ modal.course.professor }}</span>
      </p>
      <div class="h-16 mt-4">
        <Button
          class="float-right"
          text="verify"
          type="primary"
          @click="verifyClass()"
        />
      </div>
    </modal>
    <modal
      :key="modal.key"
      title="delete course"
      :active="modal.delete"
    >
      <p class="text-lg text-center mb-5">
        Are you sure you want to delete this course from this tutor application? This cannot be undone.
      </p>
      <p class="font-bold text-orange text-left">
        {{ modal.course.Class.desc }}
      </p>
      <p class="font-bold text-lg text-left mb-3">
        {{ modal.course.Class.name }}
      </p>
      <div class="h-16 mt-4">
        <Button
          class="float-right"
          text="remove"
          type="ghost"
          @click="removeClass()"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { mapActions, mapState } from "vuex";
import Button from '../base/button.vue';
import FormInput from '../base/forminput.vue';
import Modal from '../base/modal.vue';
import Class from "./class.vue";
export default {
  components: { Class, Modal, FormInput, Button, },
  setup() {
    const route = useRoute();
    const username = route.params.username;
    return { username };
  },
  data() {
    return {
      modal: {
        edit: false,
        verify: false,
        delete: false,
        key: 0,
        course: {},
      }
    }
  },
  computed: {
    ...mapState(["previewed_university"]),
    application() {
      return this.previewed_university.applications.filter(
        (application) => application._id == this.username
      )[0];
    },
  },
  methods: {
    ...mapActions(["accept_tutor", "update_application_classes"]),
    async acceptTutor() {
      console.log("accepting");
      await this.accept_tutor(this.application);
      this.$router.push(
        `/tutors/universities/${this.previewed_university.abbrev}`
      );
    },
    handleClassActions(e) {
      this.modal.edit = false;
      this.modal.verify = false;
      this.modal.delete = false;
      if(e.value == 'edit') this.modal.edit = true;
      else if(e.value == 'verify') this.modal.verify = true;
      else if(e.value == 'delete') this.modal.delete = true;
      this.modal.key ++;
      this.modal.course = {
        Class: e.Class,
        grade: e.grade,
        professor: e.professor,
        verified: e.verified
      };
    },
    async saveClass() {
      let updatedApplicationClasses = this.application.classes;
      for (let i = 0; i < updatedApplicationClasses.length; i++) {
        if (updatedApplicationClasses[i].class_info._id == this.modal.course.Class._id) {
          updatedApplicationClasses[i].grade = this.modal.course.grade;
          updatedApplicationClasses[i].professor = this.modal.course.professor;
          updatedApplicationClasses[i].verified = this.modal.course.verified;
          break;
        }
      }
      await this.update_application_classes({ user: this.application.uid._id, classes: updatedApplicationClasses });
      this.modal.edit = false;
      this.modal.key ++;
    },
    verifyClass() {
      this.modal.course.verified = true;
      this.saveClass();
      this.modal.verify = false;
      this.modal.key ++;
    },
    async removeClass() {
      let updatedApplicationClasses = this.application.classes.filter((course) => course.class_info._id != this.modal.course.Class._id);
      await this.update_application_classes({ user: this.application.uid._id, classes: updatedApplicationClasses });
      this.modal.delete = false;
      this.modal.key ++;
    },
  },
};
</script>
